.title {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
}
.summary {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid;
    padding-top: 20px;
}
.command {
    text-align: left;
    display: flex;
    flex-direction: column;
}
.wrapper-result {
    margin-top: 20px;
}
.wrapper-result-text {
    text-align: left;
}
.wrapper-btns {
    display: flex;
    border-bottom: 1px solid;
    padding-bottom: 1px;
}
.button {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    padding: 11px 20px;
    border: none;
    margin-right: 5px;
    cursor: pointer;
    background-color: rgba(50, 51, 53, 1);
    color: rgba(200, 200, 200, 1);
}
.button:hover {
    background-color: rgba(60, 61, 63, 1);
    color: rgba(255, 255, 255, 1);
}

.button:hover {
    background-color: rgba(60, 61, 63, 1);
    color: rgba(255, 255, 255, 1);
}

.button.active {
    background-color: rgba(0, 123, 255, 1);
    color: rgba(255, 255, 255, 1);
}

.button.sub {
    padding: 10px;
    font-size: 14px;
}
.wrapper-body {
}

.wrapper-charts {
    display: flex;
    flex-direction: column;
}

.wrapper-charts-buttons {
    display: flex;
    margin: 5px 0px;
    border-bottom: 1px solid;
    padding-bottom: 5px;
}

.wrapper-charts-content {
    flex: 1;
    justify-content: center;
    display: flex;
  
}

.wrapper-charts-content-child {
    display: flex;
    padding: 20px;
    gap: 20px;
    justify-content: center;
}

.error-msg {
    font-size: 24px;
    font-weight: 700;
    color: red;
}

.wrapper-result-table .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

.wrapper-result-table .table .thead-dark th {
    text-transform: capitalize;
}

.wrapper-result-table .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    padding: 0.75rem;
}

.wrapper-result-table .table thead th .thheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper-result-table .table td,
.table th {
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
}

.wrapper-result-table .table .table-item-main {
    padding: 10px 10px 10px 10px;
    font-weight: 700;
}

.wrapper-result-table .table .table-item-sub {
    padding: 3px 10px 3px 15px;
    text-transform: capitalize;
}
