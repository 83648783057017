.header-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  padding-right: 0px;
  padding-left: 0px;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #f3f3f3;
}
.dark .header-wrapper {
  @apply bg-gray-800;
}
.header-action {
  display: flex;
  align-items: center;
}
.header-action-item {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  cursor: pointer;
  border-radius: 9999px;
  padding: 0.5rem;
}
.header-action-item-hoverable {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.header-action-item-hoverable:hover {
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.4;
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.avatar {
  position: relative;
  display: inline-block;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
  width: 32px;
  height: 32px;
  min-width: 32px;
  line-height: 32px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-img {
  display: block;
  height: 85%;
  width: 85%;
  -o-object-fit: cover;
  object-fit: cover;
}
.avatar-circle {
  border-radius: 9999px;
}
