.dropdown{
  position: relative;
  display: inline-block;
}

.dropdown-menu {
    min-width: 160px;
    position: absolute;
    z-index: 30;
    border-radius: .375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding: .375rem;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
    --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
    --tw-ring-opacity: .05;
  }
  .dropdown-menu.bottom-end {
    top: 100%;
    transform-origin: top;
  }
  :is([dir="ltr"] .dropdown-menu.bottom-end) {
    right: 0px;
  }
  :is(.dark .dropdown-menu) {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / .25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  }

  .badge-wrapper {
    position: relative;
    display: flex;
  }
  .badge-dot {
    height: .5rem;
    width: .5rem;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity));
    font-size: .75rem;
    line-height: 1rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .badge-inner {
    position: absolute;
    top: 0px;
    z-index: 10;
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  :is([dir="ltr"] .badge-inner) {
    right: 0px;
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }