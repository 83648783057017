.app {
    position: relative;
}

.container {
    padding: 30px;
    height: calc(100vh - 65px);
    overflow-y: auto;
    overflow-x: auto;
    max-width: 100%;
}

