h6,
.h6 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
:is(.dark h6),
:is(.dark .h6) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
:is(.dark .avatar) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

:is(.dark .dark\:border-gray-700) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}
/* Input */
.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  padding: 0.5rem 0.75rem;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.input::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
:is(.dark .input) {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
// force default style input
.use-default .input {
  @apply text-black;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}
/* End Input */
/* Button */
.btn {
  @apply border bg-[#eee] border-gray-300 text-gray-600 rounded h-11 px-8 py-2;
  white-space: nowrap;
  font-weight: 600;
  cursor: pointer;
}
.btn.submit {
  @apply text-white;
  background-color: rgb(53 118 186 / var(--tw-bg-opacity));
}
.btn.radius-round {
  border-radius: 0.375rem;
}
// dark btn
.dark .btn {
  @apply bg-gray-700 border-gray-700 text-gray-100;
}
.dark .btn:hover {
  @apply bg-gray-600;
}
.dark .btn:active {
  @apply bg-gray-500 border-gray-500;
}
// force default style btn
.use-default .btn {
  @apply bg-[#eee] border-gray-300 text-gray-600;
}
.use-default .btn.submit {
  @apply bg-[#3576ba] text-white;
}
/* End Button */
/* Button With Icon */
.btnIcon {
  @apply relative w-full flex justify-center items-center px-12 py-3 border border-gray-300 rounded;
  .icon {
    @apply absolute left-3;
  }
}
/* End Button With Icon */
/* Switch button */
.switcher {
  min-width: 2.75rem;
  height: 1.5rem;
  position: relative;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  border-radius: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.switcher input {
  display: none;
}
.switcher-toggle {
  left: 0.125rem;
  top: 0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.switcher-toggle::before {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  border-radius: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.switcher-content {
  margin: 0 0.45rem 0 1.65rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.switcher.switcher-checked .switcher-content {
  margin: 0 1.65rem 0 0.45rem;
}
.switcher.switcher-checked .switcher-toggle,
.switcher.switcher-checked .switcher-toggle-loading {
  left: calc(100% - 1.375rem);
}
:is(.dark .switcher) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
/* End Switch button */