.file-upload-wrapper {
    display: flex;
    .file-upload-input {
        width: 300px;
        color: #000;
        font-size: 16px;
        padding: 11px 17px;
        outline: none;
        cursor: pointer;
        border: 1px solid rgba(32, 33, 35, 0.8);
    }
    .file-holder {
        display: none;
    }
}
.file-upload-button {
}
.file-remove-button {
    color: #fff;
    font-size: 16px;
    padding: 11px 20px;
    border: none;
    margin-left: -1px;
    background-color: rgba(32, 33, 35, 0.8);
    cursor: pointer;
    margin-left: 20px;
}
.file-message-details {
    text-align: left;
    .alert {
        color: red;
    }
}
