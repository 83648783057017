.filter-btn-wrapper {
    position: relative;
    margin-left: auto;
}

.filter-btn {
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.filter-btn.active {
    color: rgb(0, 123, 255);
}

.filter-btn .filter-text{
    font-size: 11px;
    margin-right: 5px;
}

.filter-btn .filter-icon{
    font-size: 18px;
}

.filter-btn .filter-reset{
    color: #000;
    font-size: 18px;
    margin-left: 5px;
    cursor: pointer;
}

.filter-options {
    position: absolute;
    right: 0;
    background: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    text-align: left;
    min-width: 220px;
    font-size: 14px;
    text-transform: initial;
}
.filter-options li {
    border-bottom: 1px solid #000;
    padding: 5px 10px;
    font-weight: 400;
    cursor: pointer;
}
.filter-options li:hover {
    background-color: rgb(60, 61, 63);
    color: #fff;
}
.filter-options li.active {
    background-color: rgb(60, 61, 63);
    color: #fff;
}
.filter-options li:last-child {
    border-bottom: unset;
}