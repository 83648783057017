.side-nav {
  z-index: 20;
  flex: 1 1 auto;
  flex-shrink: 1;
  flex-shrink: 0;
  flex-direction: column;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #003059;
  color: #fff;
}

.dark .side-nav {
  background-color: unset;
  color: unset;
}

.side-nav-collapse {
  width: 80px;
  min-width: 80px;
}

.side-nav-expand {
  position: sticky;
  top: 0px;
  height: 100vh;
  width: 290px;
  min-width: 290px;
}

.side-nav-header {
  width: 100%;
}
.side-nav-content {
  height: calc(100vh - 128px);
  overflow-y: auto;
}

.menu-title {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

.menu-item {
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
  white-space: nowrap;
  border-radius: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-weight: 600;
  min-height: 40px;
}

.menu-item.menu-item-active {
  color: #fff;
  background-color: #597893;
}

.menu-item.menu-item-transparent.menu-item-hoverable:hover {
  color: #fff;
  background-color: #597893;
}

.dark .menu-item.menu-item-transparent.menu-item-hoverable:hover {
  background-color: #ffffff1a;
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / 1);
}

.dark .menu-item.menu-item-active {
  background-color: #ffffff1a;
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / 1);
}

.admin-group{
  background: #597893;
  border-radius: 10px;
}
