.chartTitle {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
}
.chartTitleWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}