@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'assets/styles.scss';
@import 'assets/theme.scss';
html, body {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: Inter,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal;
}

:is(.dark body) {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / 1);
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / 1);
}

:is(.dark .menu-item.menu-item-transparent) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

[dir="ltr"] .ltr\:mr-2 {
  margin-right: .5rem;
}
